import React, { createContext, useContext, useState, useEffect } from 'react';

const AuthContext = createContext(null);

export const useAuth = () => useContext(AuthContext);

export const AuthProvider = ({ children }) => {

    let loggedInUserStr = sessionStorage.getItem("user");
    let loggedInUser = null;
    if (loggedInUserStr) {
        loggedInUser = JSON.parse(loggedInUserStr);
    }

    const [isAuthenticated, setIsAuthenticated] = useState(loggedInUser !== null);


    const logon = () => {
        console.log("setIsAuthenticated logon");
        setIsAuthenticated(true);
    };

    const logoff = () => {
        console.log("setIsAuthenticated logoff");
        setIsAuthenticated(false);
    };

    return (
        <AuthContext.Provider value={{ isAuthenticated, logon, logoff }}>
            {children}
        </AuthContext.Provider>
    );
};