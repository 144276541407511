import React from "react";

const Send = ({className}) => {
    const classes = `w-4 h-4 ${className}`;
    return (
        <svg xmlns="http://www.w3.org/2000/svg" 
            viewBox="0 0 24 24" 
            fill="none" 
            className={classes}
            strokeWidth={1.5}
            stroke="currentColor" >
            <path strokeLinecap="round" strokeLinejoin="round" d="M6 12 3.269 3.125A59.769 59.769 0 0 1 21.485 12 59.768 59.768 0 0 1 3.27 20.875L5.999 12Zm0 0h7.5" />
        </svg>
    );
}

export default Send;