import React, { useState } from "react";
import RssFeeds from "../rss/RssFeeds";
import { sources } from "../rss/RssSources";
import { Utils } from "../../utils";


const AsideRight = ({ className = "", forumId = "", ads = [] }) => {


    Utils.shuffle(sources);

    return (
        <aside className={"mb-[5px] md:mb-0 rounded-md md:sticky top-15 w-full md:w-56 shrink-0 flex flex-row lg:flex-col overflow-auto lg:overflow-['unset']  bg-white p-0 md:p-1  " + className}>
            <div className="flex flex-col w-full overflow-hidden">
                
                <div className={`hidden md:block text-center font-bold py-2 mb-1  border-b md:border-0x border-gray-200 rounded-t-md  bg-primary-white`}>
                    {ads.length > 0 ? 'Premium Positioning' : 'Latest About Health' }
                </div>
                <div className="max-h-[calc(100vh-130px)] overflow-y-auto">
                    {ads.length > 0 &&
                        <div className="flex flex-row lg:flex-col gap-x-1 lg:gap-x-0 lg:gap-y-1 overflow-auto lg:overflow-['unset'] sm:rounded-sm p-1 lg:p-0 ">
                            {ads.map( (ad, index)  => { 
                                let img = ad.media;
                                let style = {
                                    backgroundImage: `url('${img}')`,
                                    backgroundSize: 'cover',
                                    backgroundRepeat: 'no-repeat',
                                    backgroundPosition: 'center'
                                };
                                const ky = index + Math.random() + Math.random();
                                return (
                                    <div className="min-w-[150px] w-[150px] lg:w-full min-h-[107px]" key={ky}>
                                        <a 
                                            className="cursor-pointer" 
                                            target="_blank" 
                                            href={ad.url}>
                                            <img src={img} className="w-full  hidden lg:block" />
                                            <div className="w-full h-full rounded-md  lg:hidden" style={style} ></div>
                                        </a>
                                    </div>
                                );
                            })}
                        </div>
                    }
                    {ads.length === 0 && 
                        <div className="flex flex-row lg:flex-col gap-x-1 lg:gap-x-0 lg:gap-y-1 overflow-auto lg:overflow-['unset'] sm:rounded-sm p-1 lg:p-0">
                            {sources.map(source => {
                                return (<RssFeeds source={source} />)
                            })}
                        </div>
                    }
                </div>
            </div>

        </aside>
    );
};



export default AsideRight;