import React, { createContext, useContext, useEffect, useState } from 'react';
import { io, Socket } from 'socket.io-client';

const BASE_URL = process.env.REACT_APP_API_BASE_URL || "";

// Socket context
const SocketContext = createContext(null);

export const useSocket = () => useContext(SocketContext);

export const SocketProvider = ({ children, isAuthenticated }) => {
    const [socket, setSocket] = useState(null);
    const [socketUser, setSocketUser] = useState(null);

    useEffect(() => {
        // Connect only if the user is authenticated
        if (isAuthenticated) {
            let loggedInUserStr = sessionStorage.getItem("user");
            let loggedInUser = null;
            if (loggedInUserStr) {
                loggedInUser = JSON.parse(loggedInUserStr);

                setSocketUser(loggedInUser);

                const channelId = loggedInUser._id;

                const newSocket = io(BASE_URL, {
                    // Add any connection options here, e.g., authentication token
                    query: { channelId: channelId }
                });


                newSocket.emit('joinChannel', channelId);

                setSocket(newSocket);

                // Clean up socket connection on unmount or logout
                return () => {
                    if(socketUser){
                        leaveChannel(socketUser._id);
                    }
                    newSocket.disconnect();
                    setSocket(null);
                    setSocketUser(null);
                };
            }
        }
    }, [isAuthenticated]);

    // Method to join a specific channel
    const joinChannel = (channel) => {
        if (socket) {
            socket.emit('joinChannel', channel);
        }
    };

    // Method to leave a specific channel
    const leaveChannel = (channel) => {
        if (socket) {
            socket.emit('leaveChannel', channel);
        }
    };

    // Method to send a message to a specific channel
    const sendMessageToChannel = (channel, message) => {
        if (socket) {
            socket.emit('sendMessage', { channel, message });
        }
    };

    return (
        <SocketContext.Provider value={{socket, joinChannel, leaveChannel, sendMessageToChannel}}>
            {children}
        </SocketContext.Provider>
    );
};
