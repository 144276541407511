import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import Loader from 'react-loaders'
import toast, { Toaster } from 'react-hot-toast';
import { resetPassword, resendResetPasswordCode } from "../core/auth";
import { Utils } from "../utils";


export default function ActivateAccount() {
    let url = new URL(document.URL);
    let urlsp = url.searchParams;
    const targetEmail = urlsp.get("mb");
    let targetDecodedEmail = atob(targetEmail);
    const [isLoading, setIsLoading] = useState(false);
    const [isResendingCode, setIsResendingCode] = useState(false);
    const [email, setEmail] = useState(targetDecodedEmail);
    const [code, setCode] = useState("");
    const [password, setPassword] = useState("");
    const [password2, setPassword2] = useState("");
    const [errors, setErrors] = useState([]);
    const [passwordStrengthErrors, setPsswordStrengthErrors] = useState([]);
    const navigate = useNavigate();

    const clearForm = () => {
        setEmail("");
        setCode("");
        setPassword("");
        setPassword2("");
        setPsswordStrengthErrors([]);
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setErrors([]);
        setIsLoading(true);
        const inputs = { email, code, password, password2 };
        if (!checkPassword()) {
            return false;
        }
        let response = await resetPassword(inputs);
        setIsLoading(false);
        if (response !== true) {
            return setErrors(response);
        }
        clearForm();
        navigate("/login");
    };

    const handleResendCode = async (e) => {
        e.preventDefault();
        setErrors([]);
        setCode("");
        setIsLoading(true);
        setIsResendingCode(true);
        const inputs = { email, code };
        let response = await resendResetPasswordCode(inputs);
        setIsLoading(false);
        setIsResendingCode(false);
        if (response !== true) {
            return setErrors(response);
        }
        toast.success('Password reset code has been resent to your email.');
    }

    const checkPassword = () => {
        // check for password strength
        setPsswordStrengthErrors([]);
        const res = Utils.chaeckPasswordStrength(password);
        if (res !== true) {
            setIsLoading(false);
            setPsswordStrengthErrors([res]);
            return false;
        }
        return true;
    }

    return (
        <>
            <Toaster
                position="top-left"
                reverseOrder={false}
            />
            <div className="flex min-h-full flex-1">
                <div className="flex flex-1 flex-col justify-center px-4 py-12 sm:px-6 lg:flex-none lg:px-20 xl:px-24 bg-primary-white">
                    <div className="mx-auto w-full max-w-sm lg:w-96">
                        <div>
                            <div className="flex flex-row items-center gap-x-3">
                                <img
                                    className="h-10 w-auto"
                                    src="/logo.png"
                                    alt="wefayo organization"
                                />
                                <a href={isLoading ? '#' : '/'} className={"" + (isLoading ? "cursor-not-allowed" : "")} ><span className="text-4xl font-sans"><b className="font-sans">wefayo</b></span></a>
                            </div>


                            <h2 className="mt-8 text-2xl font-bold leading-9 tracking-tight text-gray-900">
                                Reset your password
                            </h2>
                            <p className="mt-2 text-md leading-6 text-gray-500">
                                To successfully complete resetting your password, Please enter the password reset code sent to
                                <span className="text-purple-900 mx-2 font-bold">{email}</span>
                                 if you don't see it, in your inbox - please check your spam folder or junk email folder
                            </p>
                            <p className="mt-4 text-md leading-6 text-gray-500">
                                If you didn't receive this email, click here {' '}
                                <button role="button"  type="button" disabled={isLoading} onClick={handleResendCode} className={`flex justify-center items-center flex-row  font-semibold  disabled:cursor-not-allowed ${isResendingCode ? "bg-purple-400 text-white px-3 rounded-sm " : "text-purple-900 hover:text-purple-500"}`}>

                                    {isResendingCode && <Loader type="ball-beat" style={{ transform: 'scale(0.5)' }} />}

                                    {isResendingCode ? 'Resending Code' : 'Resend Code'}
                                </button>
                            </p>
                        </div>

                        <div className="mt-10">
                            <div>
                                <form action="#" method="POST" className="space-y-3" onSubmit={handleSubmit}>

                                    <div>
                                        <label htmlFor="code" className="block text-sm font-medium leading-6 text-gray-900">
                                            Enter Code
                                            <span className="text-red-900 mx-1">*</span>
                                        </label>
                                        <div className="mt-2">
                                            <input
                                                id="code"
                                                name="code"
                                                type="text"
                                                autoComplete=""
                                                required
                                                disabled={isLoading}
                                                value={code}
                                                onChange={(e) => setCode(e.target.value)}

                                                className="app-sm-md-h block w-full rounded-sm border-0 py-1 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6 disabled:bg-gray-100 disabled:cursor-progress"
                                            />
                                        </div>
                                    </div>

                                    <div >
                                        <label htmlFor="password" className="block text-sm font-medium leading-6 text-gray-900">
                                            New Password
                                            <span className="text-red-900 mx-1">*</span>
                                        </label>
                                        <div className="mt-0">
                                            <input
                                                id="password"
                                                name="password"
                                                type="password"
                                                autoComplete="current-password"
                                                required
                                                value={password}
                                                disabled={isLoading}
                                                onChange={(e) => setPassword(e.target.value)}
                                                onBlur={(e) => checkPassword()}
                                                className="app-sm-md-h block w-full rounded-sm border-0 py-1 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6 disabled:bg-gray-100 disabled:cursor-progress"
                                            />
                                        </div>
                                    </div>

                                    {passwordStrengthErrors.length > 0 &&
                                        <div className="relative pt-4">
                                            <div className="app-error text-red-400 text-xs absolute -top-[15px]">
                                                {passwordStrengthErrors[0]}
                                            </div>
                                        </div>
                                    }

                                    <div >
                                        <label htmlFor="password" className="block text-sm font-medium leading-6 text-gray-900">
                                            Confirm New Password
                                            <span className="text-red-900 mx-1">*</span>
                                        </label>
                                        <div className="mt-0">
                                            <input
                                                id="comfirm_password"
                                                name="password"
                                                type="password"
                                                autoComplete="current-password"
                                                required
                                                value={password2}
                                                disabled={isLoading}
                                                onChange={(e) => setPassword2(e.target.value)}
                                                className="app-sm-md-h block w-full rounded-sm border-0 py-1 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6 disabled:bg-gray-100 disabled:cursor-progress"
                                            />
                                        </div>
                                    </div>

                                    <div>
                                        <button role="button" 
                                            type="submit"
                                            disabled={isLoading || passwordStrengthErrors.length > 0}
                                            className="flex justify-center items-center flex-row w-full rounded-md bg-purple-900 px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-purple-800  disabled:cursor-not-allowed "
                                        >
                                            {isLoading && <Loader type="ball-beat" style={{ transform: 'scale(0.5)' }} />}
                                            Submit
                                        </button>
                                    </div>
                                </form>
                                {errors.length > 0 &&
                                    <div className="app-error text-red-400 font-bold text-xs py-1">
                                        {errors[0]}
                                    </div>
                                }
                            </div>


                        </div>
                    </div>
                </div>
                <div className="relative hidden w-0 flex-1 lg:block">
                    <img
                        className="absolute inset-0 h-full w-full object-cover"
                        src="https://images.unsplash.com/photo-1496917756835-20cb06e75b4e?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1908&q=80"
                        alt=""
                    />
                </div>
            </div>
        </>
    )
}
