import React, { useState, useRef} from "react";
import NavHeader from "../navigation/NavHeader";
import AsideLeft from "../aside/AsideLeft";
import AsideRight from "../aside/AsideRight";
import Footer from "../navigation/Footer";
import GoUpDown from "../navigation/GoUpDown";
import Joyride from 'react-joyride';


const finishedTourConfig = localStorage.getItem("finished-tour");
const finishedTour = finishedTourConfig === "true";


const productTourConfig = {
    steps: [
        {
            target: '#menu-link-communities',
            content: 'Explore and connect with different communities, make new friends, get inspired by success stories, share your knowledge, and access exclusive content.',
            disableBeacon: true,
        },
        {
            target: '#menu-link-jobs',
            content: "Discover exciting career opportunities or post job listings for others to see.",
            disableBeacon: true,
        },
        {
            target: '#menu-link-events',
            content: 'Join fun events, including social activities, training sessions, and new programs. Some are online, while others take place onsite.',
            disableBeacon: true,
        },
        {
            target: '#menu-btn-invite',
            content: 'Invite your friends to join you! You can send invitations individually or copy the link to share on social media with many friends at once.',
            disableBeacon: true,
        },
        {
            target: '#menu-general-invite',
            content: 'Easily find members, communities, jobs, volunteer opportunities, events, or any keyword across the platform.',
            disableBeacon: true,
        },
        {
            target: '#search-community-1-form',
            content: 'Use this feature to search for specific community names.',
            disableBeacon: true,
        },
        {
            target: '#menu-invitation-leaders',
            content: 'Check out the top inviters! To make this list, send out lots of invitations and have them accepted by your friends.',
            disableBeacon: true,
        },
        {
            target: '#menu-activities-leaders',
            content: 'Meet the members with the most activity! To get on this list, engage by making posts, commenting, and liking content.',
            disableBeacon: true,
        },
        {
            target: '#menu-support',
            content: 'To add communities or get help, click here.',
            disableBeacon: true,
        },
    ]
};



const MainLayout = (props) => {
    const { currentPage } = props;
    const showAsideLeft = props?.showAsideLeft ?? true;
    let ads = [];
    if (props.ads) {
        ads = [...props.ads];
    }

    const { steps } = productTourConfig;
    const [startTour, setStartTour] = useState(false);
    const tourRenderRef = useRef(1);



    const handleStartTour = () => {
        setStartTour(true);
        tourRenderRef.current = tourRenderRef.current + 1;
    }

    const handleJoyrideCallback = (data) => {
        const { status, index, steps } = data;
        // Check if the tour is complete
        if (status === 'finished' && index === productTourConfig.steps.length - 1) {
            // Call your function to record the last step
            localStorage.setItem("finished-tour", "true");
            setStartTour(false);
        }
    }

    return (
        <div className="flex min-h-full overflow-y-auto flex-col" >

            <Joyride
                steps={steps}
                run={startTour}
                continuous={true}
                showProgress={true}
                showSkipButton={true}
                callback={handleJoyrideCallback}
                styles={{
                    options: {
                        arrowColor: '#6b21a8',
                        primaryColor: '#6b21a8'
                    },
                }}
                ref={tourRenderRef}
            />

            <NavHeader currentPage={currentPage} />
            <div className=" mx-auto max-w-6xl flex flex-col md:flex-row w-full items-start  gap-x-2 px-[5px] md:px-4 pt-[30px] md:pt-[60px] ">
                {showAsideLeft &&
                    <>
                        <AsideLeft shows={'leaderboard'} handleStartTour={handleStartTour} />
                        <AsideRight className="md:hidden" ads={ads} />
                    </>
                }
                <main className="flex-1 flex-grow self-stretch flex ">
                    {props.children}
                </main>
                <AsideRight className="hidden md:block" ads={ads} />
            </div>
            <Footer />
            <GoUpDown />
        </div>
    );
};

export default MainLayout;