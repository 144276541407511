
import React from "react";

const SingleTick = ({fillColor, className, strokeColor}) => {
    const fill = (fillColor)?fillColor:  "none";
    const stroke = (strokeColor)?strokeColor:  "currentColor";
    let classes = `w-4 h-4`;
    if(className && className.indexOf('w-') >= 0){
        classes = ``;
    }
    classes += ` ${className}`;
    return (
        <svg xmlns="http://www.w3.org/2000/svg" 
            fill={fill}
            viewBox="0 0 24 24" 
            strokeWidth="1.5" 
            stroke={stroke}
            className={classes}>
            <path stroke-linecap="round" stroke-linejoin="round" d="m4.5 12.75 6 6 9-13.5" />
        </svg>
    );
}

export default SingleTick;

